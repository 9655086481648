<template>
    <div>
        <v-row no-gutters class="mt-1 mb-5 justify-start align-top">
            <div
                class="d-flex"
                v-for="(insight, index) of insights"
                :key="index"
            >
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-card
                            class="pt-5 px-2 ma-2"
                            min-width="250px"
                            max-width="250px"
                            v-bind="attrs"
                            v-on="on"
                            @click="downloadReport(insight)"
                        >
                            <v-card-text
                                class="d-flex my-0 py-0 mx-0 px-0"
                                width="100%"
                            >
                                <v-chip
                                    class="mb-1 mt-0 mx-2 pt-0 white--text"
                                    :color="insight.color"
                                    label
                                    style="height: 50px"
                                >
                                    <v-icon center>
                                        {{ insight.icon }}
                                    </v-icon>
                                </v-chip>
                                <div class="mb-1 mt-0 pt-0">
                                    <div
                                        class="d-flex mr-0 pr-0 text-capitalize"
                                    >
                                        <span>{{ insight.title }}</span>
                                    </div>
                                    <p class="text-h6 black--text">
                                        {{ insight.value }}
                                    </p>
                                </div>
                            </v-card-text>
                        </v-card>
                    </template>
                    <span>Click here to download</span>
                </v-tooltip>
            </div>
            <v-spacer />
            <v-btn
                rounded
                color="primary"
                @click="openGenerateXLS"
                class="ma-2"
            >
                <v-icon>mdi-file-excel</v-icon>
                INFORM
            </v-btn>
        </v-row>
        <!-- InProgress-Rejected -->
        <v-data-table
            :headers="progressHeaders"
            :items="inactiveStatusData"
            class="elevation-0"
            :mobile-breakpoint="0"
            :loading="loading"
            disable-pagination
            hide-default-footer
            @click:row="openPurchases"
        >
            <!--ITEMS-->
            <template v-slot:[`item.status`]="{ item }">
                <p class="my-0 font-weight-medium">
                    {{ item.status.toUpperCase() }}
                </p>
            </template>
            <template v-slot:[`item.services`]="{ item }">
                <p class="my-0 text-capitalize">
                    {{ item.qty.services }}
                </p>
            </template>
            <template v-slot:[`item.materials`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{ item.qty.materials }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.assets`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{ item.qty.assets }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.total`]="{ item }">
                <p class="my-0 font-weight-medium">
                    {{ item.qty.total }}
                </p>
            </template>
            <template v-slot:[`body.append`]>
                <tr class="view-footer">
                    <td class="font-weight-bold text-center">
                        TOTAL
                    </td>
                    <td class="font-weight-bold text-center">
                        {{ inactiveStatusTotals.services }}
                    </td>
                    <td class="font-weight-bold text-center">
                        {{ inactiveStatusTotals.materials }}
                    </td>
                    <td class="font-weight-bold text-center">
                        {{ inactiveStatusTotals.assets }}
                    </td>
                    <td></td>
                    <td class="font-weight-bold text-center">
                        {{ inactiveStatusTotals.grandTotal }}
                    </td>
                </tr>
            </template>
        </v-data-table>
        <v-data-table
            :headers="progressHeaders"
            :items="progressPurchasingData"
            class="elevation-0 pt-5"
            :mobile-breakpoint="0"
            :loading="loading"
            disable-pagination
            hide-default-footer
            @click:row="openPurchases"
        >
            <!--ITEMS-->
            <template v-slot:[`item.status`]="{ item }">
                <p class="my-0 font-weight-medium">
                    {{ item.status.toUpperCase() }}
                </p>
            </template>
            <template v-slot:[`item.services`]="{ item }">
                <p class="my-0 text-capitalize">
                    {{ item.qty.services }}
                </p>
            </template>
            <template v-slot:[`item.materials`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{ item.qty.materials }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.assets`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{ item.qty.assets }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.total`]="{ item }">
                <p class="my-0 font-weight-medium">
                    {{ item.qty.total }}
                </p>
            </template>
            <template v-slot:[`body.append`]>
                <tr class="view-footer">
                    <td class="font-weight-bold text-center">
                        TOTAL
                    </td>
                    <td class="font-weight-bold text-center">
                        {{ progressTotals.services }}
                    </td>
                    <td class="font-weight-bold text-center">
                        {{ progressTotals.materials }}
                    </td>
                    <td class="font-weight-bold text-center">
                        {{ progressTotals.assets }}
                    </td>
                    <td></td>
                    <td class="font-weight-bold text-center">
                        {{ progressTotals.grandTotal }}
                    </td>
                </tr>
            </template>
        </v-data-table>
        <!-- InTransit-Received -->
        <v-data-table
            :headers="headers"
            :items="finalPurchasingData"
            class="elevation-0 pt-5"
            :mobile-breakpoint="0"
            :loading="loading"
            disable-pagination
            hide-default-footer
            @click:row="openPurchases"
        >
            <!--ITEMS-->
            <template v-slot:[`item.status`]="{ item }">
                <p class="my-0 font-weight-medium">
                    {{ item.status.toUpperCase() }}
                </p>
            </template>
            <template v-slot:[`item.services`]="{ item }">
                <p class="my-0 text-capitalize">
                    {{ item.qty.services }}
                </p>
            </template>
            <template v-slot:[`item.materials`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{ item.qty.materials }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.assets`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{ item.qty.assets }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.total`]="{ item }">
                <p class="my-0 font-weight-medium">
                    {{ item.qty.total }}
                </p>
            </template>
            <template v-slot:[`item.flags`]="{ item }">
                <div class="d-flex justify-center">
                    <v-btn
                        small
                        icon
                        class="mr-1"
                        color="white"
                        style="background-color: #4b9b12;"
                        @click="openDelayedView(item.delays.type0)"
                        v-if="
                            item.delays.type0.length > 0 &&
                                (item.status != 'received' ||
                                    item.status != 'closed')
                        "
                    >
                        <p class="my-0">
                            {{ item.delays.type0.length }}
                        </p>
                    </v-btn>
                    <v-btn
                        small
                        icon
                        class="mr-1"
                        color="black"
                        style="background-color: #ffc400;"
                        @click="openDelayedView(item.delays.type1)"
                        v-if="
                            item.delays.type1.length > 0 &&
                                (item.status != 'received' ||
                                    item.status != 'closed')
                        "
                    >
                        <p class="my-0">
                            {{ item.delays.type1.length }}
                        </p>
                    </v-btn>
                    <v-btn
                        small
                        icon
                        class="mr-1 ml-1"
                        color="white"
                        style="background-color: #ee5c07;"
                        @click="openDelayedView(item.delays.type2)"
                        v-if="item.delays.type2.length > 0"
                    >
                        <p class="my-0">
                            {{ item.delays.type2.length }}
                        </p>
                    </v-btn>
                    <v-btn
                        small
                        icon
                        class="ml-1"
                        color="white"
                        style="background-color: #ce0202;"
                        @click="openDelayedView(item.delays.type3)"
                        v-if="item.delays.type3.length > 0"
                    >
                        <p class="my-0">
                            {{ item.delays.type3.length }}
                        </p>
                    </v-btn>
                </div>
            </template>
            <template v-slot:[`body.append`]>
                <tr class="view-footer">
                    <td class="font-weight-bold text-center">
                        TOTAL
                    </td>
                    <td class="font-weight-bold text-center">
                        {{ finalTotals.services }}
                    </td>
                    <td class="font-weight-bold text-center">
                        {{ finalTotals.materials }}
                    </td>
                    <td class="font-weight-bold text-center">
                        {{ finalTotals.assets }}
                    </td>
                    <td></td>
                    <td class="font-weight-bold text-center">
                        {{ finalTotals.grandTotal }}
                    </td>
                    <td class="font-weight-bold text-center">
                        {{ finalTotals.delayed }}
                    </td>
                </tr>
            </template>
        </v-data-table>

        <v-row class="my-2">
            <v-col cols="3">
                <div class="d-flex align-center justify-center">
                    <v-btn
                        x-small
                        icon
                        class="mr-1"
                        color="black"
                        style="background-color: #4b9b12;"
                        disabled
                    >
                    </v-btn>
                    <strong
                        class="d-flex align-center justify-center pa-0 ma-0"
                    >
                        from {{ delayLimits[0] }} to {{ delayLimits[1] }} days
                        to due date
                    </strong>
                </div>
            </v-col>
            <v-col cols="3">
                <div class="d-flex align-center justify-center">
                    <v-btn
                        x-small
                        icon
                        class="mr-1"
                        color="black"
                        style="background-color: #ffc400;"
                        disabled
                    >
                    </v-btn>
                    <strong
                        class="d-flex align-center justify-center pa-0 ma-0"
                    >
                        Delay between {{ delayLimits[1] }} and
                        {{ delayLimits[2] }} days
                    </strong>
                </div>
            </v-col>
            <v-col cols="3">
                <div class="d-flex align-center justify-center">
                    <v-btn
                        x-small
                        icon
                        class="mr-2 ml-1"
                        color="white"
                        style="background-color: #ee5c07;"
                        disabled
                    >
                    </v-btn>
                    <strong
                        class="d-flex align-center justify-center pa-0 ma-0"
                    >
                        Delay between {{ delayLimits[2] }} and
                        {{ delayLimits[3] }} days
                    </strong>
                </div>
            </v-col>
            <v-col cols="3">
                <div class="d-flex align-center justify-center">
                    <v-btn
                        x-small
                        icon
                        class="mr-2 ml-1"
                        color="white"
                        style="background-color: #ce0202;"
                        disabled
                    >
                    </v-btn>
                    <strong
                        class="d-flex align-center justify-center pa-0 ma-0"
                    >
                        Delay of more than {{ delayLimits[3] }} days
                    </strong>
                </div>
            </v-col>
        </v-row>
        <!--ALERT MESSAGES-->
        <v-alert
            v-if="activateAlert"
            type="success"
            color="primary"
            style="position: absolute; right: 0px; bottom: 0px"
        >
            <v-row no-gutters>
                <v-col cols="11">
                    <p class="my-0">{{ alertMessage }}</p>
                </v-col>
                <v-col cols="1">
                    <v-btn
                        small
                        icon
                        class="mt-n1"
                        @click="activateAlert = false"
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-alert>

        <v-dialog persistent fullscreen v-model="openDialog">
            <PurchasesDelayed
                v-if="openDialog"
                :delayed="delayedPurchases"
                :status="delayedPurchases[0].status"
                :showDelayInfo="showDelayInfo"
                :windowTitle="windowTitle"
                @closeDialog="closeDelayedView"
            />
        </v-dialog>
        <!-- INFORM -->
        <v-dialog
            v-model="openInform"
            :retain-focus="false"
            persistent
            max-width="400"
        >
            <v-card :loading="loading" v-if="openInform">
                <v-card-title class="text-h5">
                    <v-btn
                        small
                        icon
                        @click="closeGenerateXLS"
                        color="primary"
                        class="mr-2"
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                    <h3>
                        INFORM
                    </h3></v-card-title
                >
                <v-divider class="ml-4 mr-5" />
                <v-card-text class="pt-4">
                    <v-form v-model="valid">
                        <v-row no-gutters>
                            <v-col cols="12" class="pt-4">
                                <v-menu
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-combobox
                                            class="py-0 my-0"
                                            v-model="dateText"
                                            color="primary"
                                            label="Inform Date"
                                            readonly
                                            flat
                                            hide-details
                                            v-bind="attrs"
                                            v-on="on"
                                            prepend-icon="mdi-calendar-blank-outline"
                                        ></v-combobox>
                                    </template>
                                    <v-date-picker
                                        v-model="informDate"
                                        scrollable
                                        @change="menu = false"
                                    >
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-spacer></v-spacer>
                <v-card-actions class="pb-4">
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="generateXLS"
                        color="primary"
                        :loading="loading"
                        rounded
                    >
                        GENERATE
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import PurchasesDelayed from '@/components/Purchasing/PurchasesDelayed.vue'
import { firestore } from '@/services/firebase'
import moment from 'moment'
import { mapMutations } from 'vuex'
import * as XLSX from 'xlsx/xlsx.mjs'
import API from '@/services/api'

export default {
    name: 'PurchasingStatus',
    components: {
        PurchasesDelayed,
    },
    data: () => ({
        delayedPurchases: {},
        openDialog: false,
        activateAlert: false,
        alertMessage: '',
        loading: false,
        inactiveStatusKeys: ['inProgress', 'rejected'],
        progressStatusKeys: [
            'pricing',
            'preApproval',
            'approval',
            'purchasing',
        ],
        finalStatusKeys: ['inTransit', 'received'],
        progressHeaders: [
            {
                text: 'STATUS',
                value: 'status',
                sortable: false,
                align: 'center',
            },
            {
                text: 'SERVICES',
                value: 'services',
                sortable: false,
                align: 'center',
            },
            {
                text: 'MATERIALS',
                value: 'materials',
                sortable: false,
                align: 'center',
            },
            {
                text: 'ASSETS',
                value: 'assets',
                sortable: false,
                align: 'center',
            },
            {
                text: 'AVERAGE DAYS',
                value: 'averageDays',
                sortable: false,
                align: 'center',
            },
            {
                text: 'TOTAL',
                value: 'total',
                sortable: false,
                align: 'center',
            },
        ],
        headers: [
            {
                text: 'STATUS',
                value: 'status',
                sortable: false,
                align: 'center',
            },
            {
                text: 'SERVICES',
                value: 'services',
                sortable: false,
                align: 'center',
            },
            {
                text: 'MATERIALS',
                value: 'materials',
                sortable: false,
                align: 'center',
            },
            {
                text: 'ASSETS',
                value: 'assets',
                sortable: false,
                align: 'center',
            },
            {
                text: 'AVERAGE DAYS',
                value: 'averageDays',
                sortable: false,
                align: 'center',
            },
            {
                text: 'TOTAL',
                value: 'total',
                sortable: false,
                align: 'center',
            },
            {
                text: 'DELAYED',
                value: 'flags',
                sortable: false,
                align: 'center',
            },
        ],
        inactiveStatusData: [],
        progressPurchasingData: [],
        finalPurchasingData: [],
        purchases: [],
        inactiveStatusOptions: {},
        progreessStatusOptions: {},
        inactiveStatusTotals: {},
        progressTotals: {},
        finalTotals: {},
        companyId: JSON.parse(localStorage.getItem('company')),
        listener: null,
        windowTitle: '',
        showDelayInfo: false,
        logPurchases: [],
        insights: [
            {
                value: 0,
                color: '#3498DB',
                iconColor: '#1A5276',
                icon: 'mdi-arrow-down-bold',
                title: 'Received at Pricing today',
            },
            {
                value: 0,
                color: '#ffa414',
                iconColor: '#2874A6',
                icon: 'mdi-arrow-right-bold',
                title: 'Sent To Preapproval Today',
            },
            {
                value: 0,
                color: '#1D8348',
                iconColor: '#2874A6',
                icon: 'mdi-arrow-up-bold',
                title: 'Sent to In Transit today',
            },
        ],
        delayLimits: [3, 1, 6, 12],
        openInform: false,
        menu: false,
        informDate: null,
        valid: false,
        informData: [],
        users: [],
    }),
    computed: {
        dateText() {
            return this.informDate
        },
    },
    async created() {
        try {
            this.loading = true
            const {
                data: { users },
            } = await API.getLiteUsers()
            this.users = users
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    async mounted() {
        try {
            this.loading = true
            const {
                data: { user },
            } = await API.getMyInfo()

            let queryList = []

            let InProgressQuery = firestore
                .collection('companies')
                .doc(this.companyId)
                .collection('purchases')
                .where('status', '==', 'inProgress')

            if (!user.permissions.includes('allPurchases')) {
                InProgressQuery = InProgressQuery.where(
                    'createdBy.id',
                    '==',
                    user.id
                )
            }
            queryList.push(InProgressQuery)

            let PricingQuery = firestore
                .collection('companies')
                .doc(this.companyId)
                .collection('purchases')
                .where('status', '==', 'pricing')

            if (!user.permissions.includes('allPurchases')) {
                PricingQuery = PricingQuery.where('buyer', '==', user.id)
            }
            queryList.push(PricingQuery)

            let PreApprovalQuery = firestore
                .collection('companies')
                .doc(this.companyId)
                .collection('purchases')
                .where('status', '==', 'preApproval')

            if (!user.permissions.includes('allPurchases')) {
                PreApprovalQuery = PreApprovalQuery.where(
                    'projectManager.id',
                    '==',
                    user.id
                )
            }
            queryList.push(PreApprovalQuery)

            let ApprovalQuery = firestore
                .collection('companies')
                .doc(this.companyId)
                .collection('purchases')
                .where('status', '==', 'approval')

            if (!user.permissions.includes('allPurchases')) {
                ApprovalQuery = ApprovalQuery.where(
                    'projectManager.id',
                    '==',
                    user.id
                )
            }
            queryList.push(ApprovalQuery)

            let PurchasingQuery = firestore
                .collection('companies')
                .doc(this.companyId)
                .collection('purchases')
                .where('status', '==', 'purchasing')

            if (!user.permissions.includes('allPurchases')) {
                PurchasingQuery = PurchasingQuery.where('buyer', '==', user.id)
            }
            queryList.push(PurchasingQuery)

            let InTransitQuery = firestore
                .collection('companies')
                .doc(this.companyId)
                .collection('purchases')
                .where('status', '==', 'inTransit')

            if (!user.permissions.includes('allPurchases')) {
                InTransitQuery = InTransitQuery.where(
                    'createdBy.id',
                    '==',
                    user.id
                )
            }
            queryList.push(InTransitQuery)

            let ReceivedQuery = firestore
                .collection('companies')
                .doc(this.companyId)
                .collection('purchases')
                .where('status', '==', 'received')

            queryList.push(ReceivedQuery)

            let RejectedQuery = firestore
                .collection('companies')
                .doc(this.companyId)
                .collection('purchases')
                .where('status', '==', 'rejected')

            if (!user.permissions.includes('allPurchases')) {
                RejectedQuery = RejectedQuery.where(
                    'createdBy.id',
                    '==',
                    user.id
                )
            }
            queryList.push(RejectedQuery)

            for (const query of queryList) {
                this.listener = await query.onSnapshot(async docSnapshot => {
                    docSnapshot.docChanges().forEach(async change => {
                        const request = Object.assign(change.doc.data(), {
                            id: change.doc.id,
                        })
                        this.handlePurchaseChange(change.type, request)
                    })
                    this.segmentByState()
                })
            }

            this.getInsights()
        } catch (error) {
            console.log(error)
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    beforeDestroy() {
        this.listener()
        this.listener = null
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        async downloadReport(insight) {
            try {
                this.loading = true
                let purchases = insight.purchases
                const projects = await API.getLiteProjects({ all: true })
                const reportData = []
                for (const purchase of purchases) {
                    let row = {}
                    const {
                        approvedBy,
                        approvedDate,
                        buyer,
                        createdBy,
                        createdOn,
                        dueDate,
                        deliveryLocation,
                        items,
                        number,
                        project,
                        projectManager,
                        status,
                        type,
                        inTransitBy,
                        preApprovedBy,
                        purchasedBy,
                        receivedBy,
                        closedBy,
                        inProgressBy,
                        inProgressDate,
                        pricingBy,
                        pricingDate,
                        purchasedDate,
                        updatedOn,
                        preApprovedDate,
                        purchaseOrders,
                        quotes,
                        attachments,
                        projectId,
                        receivedDate,
                        inTransitDate,
                        closedDate,
                        assignBy,
                        assignDate,
                        onHoldBy,
                        onHoldDate,
                        ...params
                    } = purchase
                    delete params.id
                    let purchaseOrdersString = ''
                    if (purchaseOrders && purchaseOrders.length > 0) {
                        for (const index in purchaseOrders) {
                            purchaseOrdersString += purchaseOrders[index]
                            if (index < purchaseOrders.length - 1) {
                                purchaseOrdersString += ', '
                            }
                        }
                    }

                    let quotesString = ''
                    if (quotes && quotes.length > 0) {
                        for (const index in quotes) {
                            quotesString += quotes[index]
                            if (index < quotes.length - 1) {
                                quotesString += ', '
                            }
                        }
                    }

                    let attachmentsString = ''
                    if (attachments && attachments.length > 0) {
                        for (const index in attachments) {
                            attachmentsString += attachments[index]
                            if (index < attachments.length - 1) {
                                attachmentsString += ', '
                            }
                        }
                    }

                    const projectData = projects.find(p => p.id == projectId)
                    let currency = ''
                    if (projectData && projectData.currency) {
                        currency = projectData.currency
                    }
                    let totalPrice = ''
                    if (projectData && projectData.price) {
                        totalPrice = projectData.price
                    }

                    let itemsString = ''
                    let code = ''
                    let qty = ''
                    let CO = ''
                    let supplier = ''
                    for (let i = 0; i < items.length; i++) {
                        if (items && items.length > 0) {
                            code = `${items[i].code}`
                            itemsString = `${items[i].description}`
                            qty = `${items[i].quantity}`
                            if (
                                items[i].purchaseOrder &&
                                items[i].purchaseOrder.orderNumber
                            ) {
                                CO = `${items[i].purchaseOrder.orderNumber}`
                            }
                            if (items[i].quotes && items[i].quotes.length > 0) {
                                const selectedQuote = items[i].quotes.find(
                                    q => q.able == true
                                )
                                if (selectedQuote) {
                                    supplier = selectedQuote.supplier.name
                                }
                            }
                        }

                        let unitPrice =
                            items[i].quotes && items[i].quotes.length > 0
                                ? items[i].quotes[0].price
                                : ''

                        row.id = number
                        row.project = project ? project.name : ''
                        row.projectManager = projectManager
                            ? projectManager.name
                            : ''
                        row.dueDate = dueDate
                            ? this.formatDate(dueDate._seconds)
                            : ''
                        row.deliveryLocation = deliveryLocation
                        row.order = code
                        row.items = itemsString
                        row.quantity = qty
                        row.unit = unitPrice
                        row.totalPriceItem = qty * unitPrice
                        row.CO = CO
                        row.supplier = supplier
                        row.type = type
                        row.projectTotalPrice = totalPrice
                        row.currency = currency
                        row.status = status
                        row.buyer = this.getUser(buyer)
                        row.createdBy = this.getUser(createdBy)
                        row.createdOn = createdOn
                            ? this.formatDate(createdOn._seconds)
                            : ''
                        row.inProgressBy = this.getUser(inProgressBy)
                        row.inProgressDate = inProgressDate
                            ? this.formatDate(inProgressDate._seconds)
                            : ''
                        row.assignBy = this.getUser(assignBy)
                        row.assignDate = assignDate
                            ? this.formatDate(assignDate._seconds)
                            : ''
                        row.onHoldBy = this.getUser(onHoldBy)
                        row.onHoldDate = onHoldDate
                            ? this.formatDate(onHoldDate._seconds)
                            : ''
                        row.preApprovedBy = this.getUser(pricingBy)
                        row.preApprovedDate = pricingDate
                            ? this.formatDate(pricingDate._seconds)
                            : ''
                        row.approvedBy = this.getUser(preApprovedBy)
                        row.approvedDate = preApprovedDate
                            ? this.formatDate(preApprovedDate._seconds)
                            : ''
                        row.purchasedBy = this.getUser(approvedBy)
                        row.purchasedDate = approvedDate
                            ? this.formatDate(approvedDate._seconds)
                            : ''
                        row.inTransitBy = this.getUser(purchasedBy)
                        row.inTransitDate = purchasedDate
                            ? this.formatDate(purchasedDate._seconds)
                            : ''
                        row.receivedBy = this.getUser(inTransitBy)
                        row.receivedDate = inTransitDate
                            ? this.formatDate(inTransitDate._seconds)
                            : ''
                        row.vendorDate =
                            items[i].purchaseOrder &&
                            items[i].purchaseOrder.dueDate
                                ? this.formatDate(
                                      items[i].purchaseOrder.dueDate._seconds
                                  )
                                : ''
                        row.closeBy = this.getUser(receivedBy)
                        row.closeDate = receivedDate
                            ? this.formatDate(receivedDate._seconds)
                            : ''
                        row.closedBy = this.getUser(closedBy)
                        row.closeDate = closedDate
                            ? this.formatDate(closedDate._seconds)
                            : ''
                        row.updatedOn = updatedOn
                            ? this.formatDate(updatedOn._seconds)
                            : ''
                        row.purchaseOrders = purchaseOrdersString
                        row.quotes = quotesString
                        row.attachments = attachmentsString
                        row = {
                            ...row,
                            ...params,
                        }
                        reportData.push(row)
                        itemsString = ''
                        row = {}
                    }
                }
                const reportWS = XLSX.utils.json_to_sheet(reportData)
                const wb = XLSX.utils.book_new()
                XLSX.utils.book_append_sheet(wb, reportWS, 'report')
                XLSX.writeFile(wb, `${insight.title}_report.xlsx`)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        getUser(id) {
            const user = this.users.find(u => u.id == id)
            if (user) {
                return user.name
            }
            return null
        },
        handlePurchaseChange(type, request) {
            const index = this.purchases.findIndex(r => r.id == request.id)

            if (type === 'added') {
                if (index === -1) {
                    this.purchases.push(request)
                }
            } else if (type === 'modified') {
                if (index >= 0) {
                    this.purchases.splice(index, 1, request)
                }
            } else if (type === 'removed') {
                if (index >= 0) {
                    this.purchases.splice(index, 1)
                }
            }
        },
        segmentByState() {
            this.inactiveStatusData = []
            this.progressPurchasingData = []
            this.finalPurchasingData = []
            const inactiveStatusOptions = {
                inProgress: [],
                rejected: [],
            }
            const progressStatusOptions = {
                pricing: [],
                preApproval: [],
                approval: [],
                purchasing: [],
            }
            const finalStatusOptions = {
                inTransit: [],
                received: [],
            }

            this.purchases.forEach(purchase => {
                if (this.inactiveStatusKeys.includes(purchase.status)) {
                    inactiveStatusOptions[purchase.status].push(purchase)
                }
                if (this.progressStatusKeys.includes(purchase.status)) {
                    progressStatusOptions[purchase.status].push(purchase)
                }
                if (this.finalStatusKeys.includes(purchase.status)) {
                    finalStatusOptions[purchase.status].push(purchase)
                }
            })

            this.inactiveStatusOptions = inactiveStatusOptions
            this.progressStatusOptions = progressStatusOptions
            this.finalStatusOptions = finalStatusOptions
            this.extractStatusData()
        },
        extractStatusData() {
            const now = new Date()
            const inactiveStatus = this.inactiveStatusOptions
            const progressStatus = this.progressStatusOptions
            const finalStatus = this.finalStatusOptions
            this.inactiveStatusKeys.forEach(statusKey => {
                let data = this.getData(statusKey, inactiveStatus)
                this.inactiveStatusData.push(data)
            })
            this.progressStatusKeys.forEach(statusKey => {
                let data = this.getData(statusKey, progressStatus)
                this.progressPurchasingData.push(data)
            })

            this.finalStatusKeys.forEach(statusKey => {
                const purchases = finalStatus[statusKey]
                var data = {
                    status: statusKey,
                    qty: {
                        materials: 0,
                        services: 0,
                        assets: 0,
                        total: 0,
                    },
                    delays: {
                        type0: [],
                        type1: [],
                        type2: [],
                        type3: [],
                    },
                    averageDays: 0,
                    purchases: [],
                }

                let totalDays = 0
                purchases.forEach(purchase => {
                    if (purchase.type == 'material') {
                        data.qty.materials++
                    } else if (purchase.type == 'service') {
                        data.qty.services++
                    } else if (purchase.type == 'asset') {
                        data.qty.assets++
                    }
                    data.qty.total =
                        data.qty.services + data.qty.materials + data.qty.assets

                    const dueDateSeconds = purchase.dueDate.seconds
                    const dueDateMilliseconds = dueDateSeconds * 1000
                    const dueDate = new Date(dueDateMilliseconds)

                    const timeDifference = now.getTime() - dueDate.getTime()
                    const daysDifference = Math.ceil(
                        timeDifference / (1000 * 3600 * 24)
                    )
                    if (
                        daysDifference >= -this.delayLimits[0] &&
                        daysDifference < this.delayLimits[1]
                    ) {
                        data.delays.type0.push(purchase)
                    } else if (
                        daysDifference >= this.delayLimits[1] &&
                        daysDifference <= this.delayLimits[2]
                    ) {
                        data.delays.type1.push(purchase)
                    } else if (
                        daysDifference > this.delayLimits[2] &&
                        daysDifference <= this.delayLimits[3]
                    ) {
                        data.delays.type2.push(purchase)
                    } else if (daysDifference > this.delayLimits[3]) {
                        data.delays.type3.push(purchase)
                    }

                    const statusDate = purchase.updatedOn
                        ? purchase.updatedOn
                        : purchase.createdOn
                    const dateSeconds = statusDate.seconds

                    const dateMilliseconds = dateSeconds * 1000
                    const date = new Date(dateMilliseconds)
                    const statusTimeDifference = now.getTime() - date.getTime()
                    const statusDaysDifference =
                        statusTimeDifference / (1000 * 3600 * 24)
                    totalDays += statusDaysDifference
                    purchase.daysInStatus = statusDaysDifference.toFixed(1)
                    data.purchases.push(purchase)
                })
                if (purchases.length > 0) {
                    data.averageDays = (totalDays / purchases.length).toFixed(1)
                }

                this.finalPurchasingData.push(data)
            })
            this.calculateTotals()
        },
        formatNumber(number) {
            return new Intl.NumberFormat('de-DE').format(
                Math.ceil(number != undefined ? number : 0)
            )
        },
        formatDate(seconds) {
            return moment.unix(seconds).format('YYYY-MM-DD')
        },
        calculateTotals() {
            let inactiveTotals = {
                services: 0,
                materials: 0,
                assets: 0,
                grandTotal: 0,
            }
            this.inactiveStatusData.forEach(status => {
                inactiveTotals.services += status.qty.services
                inactiveTotals.materials += status.qty.materials
                inactiveTotals.assets += status.qty.assets
                inactiveTotals.grandTotal += status.qty.total
            })
            this.inactiveStatusTotals = inactiveTotals

            let progressTotals = {
                services: 0,
                materials: 0,
                assets: 0,
                grandTotal: 0,
            }
            this.progressPurchasingData.forEach(status => {
                progressTotals.services += status.qty.services
                progressTotals.materials += status.qty.materials
                progressTotals.assets += status.qty.assets
                progressTotals.grandTotal += status.qty.total
            })
            this.progressTotals = progressTotals
            let finalTotals = {
                services: 0,
                materials: 0,
                assets: 0,
                grandTotal: 0,
                delayed: 0,
            }
            this.finalPurchasingData.forEach(status => {
                finalTotals.services += status.qty.services
                finalTotals.materials += status.qty.materials
                finalTotals.assets += status.qty.assets
                finalTotals.grandTotal += status.qty.total
                finalTotals.delayed +=
                    status.delays.type0.length +
                    status.delays.type1.length +
                    status.delays.type2.length +
                    status.delays.type3.length
            })
            this.finalTotals = finalTotals
        },
        openDelayedView(purchases) {
            this.delayedPurchases = purchases
            this.windowTitle = 'Delayed Purchases: '
            this.showDelayInfo = true
            this.openDialog = true
        },
        openPurchases(data) {
            this.delayedPurchases = data.purchases
            this.windowTitle = 'Purchases: '
            this.showDelayInfo =
                data.status == 'inTransit' || data.status == 'received'
            this.openDialog = true
        },
        closeDelayedView() {
            this.openDialog = false
            this.delayedPurchases = {}
        },
        getData(statusKey, statusOptions) {
            const now = new Date()
            const purchases = statusOptions[statusKey]
            var data = {
                status: statusKey,
                qty: {
                    materials: 0,
                    services: 0,
                    assets: 0,
                    total: 0,
                },
                averageDays: 0,
                purchases: [],
            }

            let totalDays = 0
            purchases.forEach(purchase => {
                if (purchase.type == 'material') {
                    data.qty.materials++
                } else if (purchase.type == 'service') {
                    data.qty.services++
                } else if (purchase.type == 'asset') {
                    data.qty.assets++
                }
                data.qty.total =
                    data.qty.services + data.qty.materials + data.qty.assets

                const statusDate = purchase.updatedOn
                    ? purchase.updatedOn
                    : purchase.createdOn
                const dateSeconds = statusDate.seconds
                const dateMilliseconds = dateSeconds * 1000
                const date = new Date(dateMilliseconds)
                const timeDifference = now.getTime() - date.getTime()
                const daysDifference = timeDifference / (1000 * 3600 * 24)
                totalDays += daysDifference
                purchase.daysInStatus = daysDifference.toFixed(1)
                data.purchases.push(purchase)
            })
            if (purchases.length > 0) {
                data.averageDays = (totalDays / purchases.length).toFixed(1)
            }
            return data
        },
        closeGenerateXLS() {
            this.openInform = false
            this.informDate = null
        },
        openGenerateXLS() {
            this.openInform = true
        },
        async generateXLS() {
            try {
                this.loading = true
                const statusInform = await API.getPurchasesStatusInform({
                    informDate: this.informDate,
                })
                const reportData = []
                for (const data of statusInform) {
                    let row = {}
                    const { status, service, material, asset, total } = data

                    row.status = status
                    row.services = service
                    row.materials = material
                    row.assets = asset
                    row.total = total
                    reportData.push(row)
                }

                const reportWS = XLSX.utils.json_to_sheet(reportData)
                const wb = XLSX.utils.book_new()
                XLSX.utils.book_append_sheet(wb, reportWS, 'report')
                XLSX.writeFile(
                    wb,
                    `PurchaseStatusInform_${this.informDate}.xlsx`
                )
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },

        async getInsights() {
            //Set indicators
            this.insights[0].value = 0
            this.insights[1].value = 0
            this.insights[2].value = 0

            var currentDate = new Date()
            var endDate = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth(),
                currentDate.getDate() + 1
            )
            var startDate = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth(),
                currentDate.getDate()
            )

            var startDateStr =
                startDate.toISOString().slice(0, 10) + 'T05:00:00'
            var endDateStr = endDate.toISOString().slice(0, 10) + 'T04:59:59'
            this.logPurchases = await API.purchasesChangelog({
                start: startDateStr,
                end: endDateStr,
            })
            let counter = { pricing: [], preApproval: [], inTransit: [] }
            let date = currentDate.toISOString().slice(0, 10)
            this.logPurchases.forEach(purchase => {
                if (
                    purchase.inProgressDate &&
                    this.formatDate(purchase.inProgressDate._seconds) == date
                ) {
                    if (!counter.pricing.find(p => p.id == purchase.id)) {
                        counter.pricing.push(purchase)
                    }
                }

                if (
                    purchase.pricingDate &&
                    this.formatDate(purchase.pricingDate._seconds) == date
                ) {
                    if (!counter.preApproval.find(p => p.id == purchase.id)) {
                        counter.preApproval.push(purchase)
                    }
                }
                if (
                    purchase.purchasedDate &&
                    this.formatDate(purchase.purchasedDate._seconds) == date
                ) {
                    if (!counter.inTransit.find(p => p.id == purchase.id)) {
                        counter.inTransit.push(purchase)
                    }
                }
            })
            this.insights[0].value = counter.pricing.length
            this.insights[0].purchases = counter.pricing
            this.insights[1].value = counter.preApproval.length
            this.insights[1].purchases = counter.preApproval
            this.insights[2].value = counter.inTransit.length
            this.insights[2].purchases = counter.inTransit
        },
    },
}
</script>

<style>
.v-data-table {
    border: 1px solid #eeeeee;
}
thead {
    background: #eeeeee;
}
.v-input .v-label {
    font-size: 12px;
}
.view-footer {
    background-color: #eee;
}
</style>
