<template>
    <v-card :loading="loading">
        <v-card-title>
            <v-btn small icon @click="close" color="primary" class="mr-2">
                <v-icon>
                    mdi-close
                </v-icon>
            </v-btn>
            <h3 v-if="createForm">Add New Item</h3>
            <h3 v-else>Edit Item</h3>
        </v-card-title>
        <v-divider class="ml-4 mr-5" />
        <v-card-text>
            <v-form v-model="valid">
                <v-row no-gutters class="my-4">
                    <v-col cols="12">
                        <v-autocomplete
                            v-if="request.type == 'material'"
                            v-model="selectedItem"
                            hide-details
                            label="ITEM"
                            prepend-icon="mdi-barcode"
                            :items="items"
                            item-text="codeAndDescription"
                            item-id="id"
                            :filter="filterItems"
                            :rules="[rules.required]"
                            required
                        />
                        <v-text-field
                            v-else
                            v-model="selectedItem"
                            hide-details
                            label="ITEM"
                            prepend-icon="mdi-barcode"
                            :rules="[rules.required]"
                            required
                        />
                    </v-col>
                </v-row>
                <v-row no-gutters class="my-4">
                    <v-col cols="12">
                        <v-text-field
                            v-model="item.quantity"
                            hide-details
                            label="QTY"
                            prepend-icon="mdi-view-grid-plus-outline"
                            type="number"
                            :rules="[rules.required, rules.number]"
                            required
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row no-gutters class="my-4">
                    <v-col cols="12">
                        <v-file-input
                            chips
                            label="Picture"
                            v-model="picture"
                            hide-details
                        ></v-file-input>
                    </v-col>
                </v-row>
                <v-row no-gutters class="my-4" ref="notes">
                    <v-col cols="12">
                        <v-textarea
                            v-model="item.notes"
                            rows="2"
                            hide-details
                            label="Notes"
                            prepend-icon="mdi-text"
                        >
                        </v-textarea>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                rounded
                color="primary"
                :loading="loading"
                :disabled="!valid"
                class="mb-5 mt-n2"
                @click="save"
            >
                {{ createForm ? 'ADD NEW ITEM' : 'UPDATE' }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { objDiff } from '@/helpers/objDiff.js'
import API from '@/services/api'
import { mapMutations } from 'vuex'
import { loadImage } from '@/helpers/imageHandler'
import { storage } from '@/services/firebase'

export default {
    name: 'AddNewItem',
    props: {
        request: {
            type: Object,
            default: () => {},
        },
        item: {
            type: Object,
            default: () => undefined,
        },
        originalItem: {
            type: Object,
            default: () => undefined,
        },
        createForm: {
            type: Boolean,
            default: () => true,
        },
        requestId: {
            type: String,
            default: () => undefined,
        },
    },
    data: () => ({
        loading: false,
        valid: false,
        items: [],
        selectedItem: undefined,
        rules: {
            required: v => !!v || 'The value is required',
            number: v => v > 0 || 'The value is invalid',
        },
        company: JSON.parse(localStorage.getItem('company')),
        picture: null,
        user: {},
        setting: {},
        specialMaterialIds: [],
    }),
    async mounted() {
        try {
            const {
                data: { user },
            } = await API.getMyInfo()
            this.user = user
            const {
                data: { settings },
            } = await API.getSettings()
            this.setting = settings.find(
                setting => setting.name == 'SpecialMaterials'
            )
            if (this.setting) {
                this.setting.materials.forEach(item =>
                    this.specialMaterialIds.push(item.material)
                )
            }
            if (this.request.type == 'material') await this.getStock()
            if (this.item.code) {
                this.selectedItem = `(${this.item.code}) ${this.item.description}`
            }
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    watch: {
        selectedItem() {
            if (this.selectedItem) {
                const item = this.items.find(
                    item =>
                        item.description ==
                            (typeof this.selectedItem == 'string'
                                ? this.selectedItem
                                : this.selectedItem.description) ||
                        item.description ==
                            (typeof this.selectedItem == 'string'
                                ? this.selectedItem
                                : `(${this.selectedItem.code}) ${this.selectedItem.description}`)
                )
                if (!item) {
                    this.item.description =
                        typeof this.selectedItem == 'string'
                            ? this.selectedItem
                            : this.selectedItem.short_material_text
                    this.item.code = ''
                } else {
                    this.item.description = item.short_material_text
                    this.item.code = `${item.material}`
                }
            }
        },
    },
    computed: {
        itemDiff() {
            if (!this.createForm) {
                return objDiff(this.originalItem, this.item)
            } else {
                return null
            }
        },
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        filterItems(item, queryText, itemText) {
            return (
                itemText
                    .toLocaleLowerCase()
                    .indexOf(queryText.toLocaleLowerCase()) > -1 ||
                `${item.material}`
                    .toLocaleLowerCase()
                    .indexOf(queryText.toLocaleLowerCase()) > -1
            )
        },
        async getStock() {
            try {
                this.loading = true
                this.items = await API.getStockCodes()
                this.items.forEach(item => {
                    item.codeAndDescription = item.description
                })
                if (!this.user.permissions.includes('showSpecialMaterials')) {
                    this.items = this.items.filter(
                        item => !this.specialMaterialIds.includes(item.material)
                    )
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async save() {
            if (this.createForm) {
                await this.createItem()
            } else {
                await this.updateItem()
            }
        },
        uploadImage: async function(file) {
            const path = `${this.company}/purchases/${this.requestId}/images`
            const storageRef = storage().ref(path)
            const docRef = storageRef.child(file.name)
            const result = await docRef.put(file)
            return result
        },
        async createItem() {
            try {
                this.loading = true
                if (this.requestId) {
                    if (this.picture) {
                        await this.uploadImage(this.picture)
                        this.item.picture = this.picture.name
                    }
                    const item = await API.createPurchaseRequestsItem(
                        this.requestId,
                        this.item
                    )
                    if (item.picture) {
                        item.image = await loadImage(
                            `${this.company}/purchases/${this.requestId}/images`,
                            item.picture
                        )
                    }
                    this.$emit('addItem', {
                        ...item,
                        dueDate: this.item.dueDate,
                    })
                } else {
                    if (this.picture) {
                        this.item.image = URL.createObjectURL(this.picture)
                        this.item.picture = this.picture
                    }
                    this.$emit('addItem', this.item)
                }
                this.close()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async updateItem() {
            try {
                this.loading = true
                if (this.requestId) {
                    if (this.picture) {
                        if (this.item.picture) {
                            await this.deleteItemImage(this.item.picture)
                        }
                        await this.uploadImage(this.picture)
                        this.itemDiff.picture = this.picture.name
                    }
                    const updatedItem = await API.updatePurchaseRequestsItem(
                        this.requestId,
                        this.item.id,
                        this.itemDiff
                    )
                    if (updatedItem.picture) {
                        updatedItem.image = await loadImage(
                            `${this.company}/purchases/${this.requestId}/images`,
                            updatedItem.picture
                        )
                    }
                    this.$emit('replaceItem', updatedItem)
                } else {
                    if (this.picture) {
                        this.itemDiff.image = URL.createObjectURL(this.picture)
                        this.itemDiff.picture = this.picture
                    }
                    this.$emit('replaceItem', this.itemDiff)
                }
                this.close()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async deleteItemImage(file) {
            try {
                const path = `${this.company}/purchases/${this.requestId}/images`
                const storageRef = storage().ref(path)
                const docRef = storageRef.child(file)
                await docRef.delete().catch(() => {
                    throw new Error('Could not delete file.')
                })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        close() {
            this.$emit('closeDialog')
        },
    },
}
</script>

<style></style>
