<template>
    <v-card :loading="loading">
        <v-card-title>
            <v-btn small icon @click="close" color="primary" class="mr-2">
                <v-icon>
                    mdi-close
                </v-icon>
            </v-btn>
            <h3>Quotes</h3>
        </v-card-title>
        <v-divider class="ml-4 mr-5" />
        <v-card-text>
            <v-data-table
                :headers="headers"
                :items="item.quotes"
                class="elevation-0 mt-6"
                :mobile-breakpoint="0"
                :loading="loading"
                disable-pagination
                hide-default-footer
            >
                <template
                    v-slot:[`item.date`]="{
                        item,
                    }"
                >
                    <p class="my-0">
                        {{
                            formatDate(
                                item.createdOn.seconds ||
                                    item.createdOn._seconds
                            )
                        }}
                    </p>
                </template>
                <template
                    v-slot:[`item.select`]="{
                        item,
                    }"
                >
                    <v-simple-checkbox
                        color="success"
                        v-model="item.able"
                        :disabled="true"
                    ></v-simple-checkbox>
                </template>
                <template
                    v-slot:[`item.receivedBy`]="{
                        item,
                    }"
                >
                    <p class="my-0">
                        {{ item.receivedBy.name }}
                    </p>
                </template>
                <template
                    v-slot:[`item.supplier`]="{
                        item,
                    }"
                >
                    <p class="my-0">
                        {{ item.supplier.name }}
                    </p>
                </template>
                <template
                    v-slot:[`item.contact`]="{
                        item,
                    }"
                >
                    <p class="my-0">
                        {{ item.contact.name }}
                    </p>
                </template>
                <template
                    v-slot:[`item.price`]="{
                        item,
                    }"
                >
                    <p class="my-0">
                        {{ numberFormat(item.price) }}
                    </p>
                </template>
                <template
                    v-slot:[`item.days`]="{
                        item,
                    }"
                >
                    <p class="my-0">{{ item.days }} days</p>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
import moment from 'moment'

export default {
    name: 'ItemQuotes',
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        loading: false,
        headers: [
            {
                text: 'Quotes',
                value: 'select',
                align: 'center',
                sortable: false,
            },
            {
                text: 'DATE',
                value: 'date',
                align: 'center',
                sortable: false,
            },
            {
                text: 'Supplier',
                value: 'supplier',
                align: 'center',
                sortable: false,
            },
            {
                text: 'Price',
                value: 'price',
                align: 'center',
                sortable: false,
            },
            {
                text: 'Time',
                value: 'days',
                align: 'center',
                sortable: false,
            },
            {
                text: 'Notes',
                value: 'notes',
                align: 'left',
                sortable: false,
            },
        ],
    }),
    methods: {
        numberFormat(amount) {
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
            }).format(Math.ceil(amount))
        },
        openFile(url) {
            window.open(url, '_blank')
        },
        formatDate(seconds) {
            return moment.unix(seconds).format('YYYY-MM-DD HH:mm')
        },
        close() {
            this.$emit('close')
        },
    },
}
</script>

<style>
.v-data-table {
    border: 1px solid #eeeeee;
}
thead {
    background: #eeeeee;
}
</style>
