<template>
    <v-card :loading="loading">
        <v-card-title>
            <v-btn small icon @click="close" color="primary" class="mr-2">
                <v-icon>
                    mdi-close
                </v-icon>
            </v-btn>
            <h3 v-if="createForm">Add New Entry</h3>
            <h3 v-else>Edit Entry</h3>
        </v-card-title>
        <v-divider class="ml-4 mr-5" />
        <v-card-text>
            <v-form v-model="valid">
                <v-row no-gutters class="my-4">
                    <v-col cols="12">
                        <v-text-field
                            v-model="entry.quantity"
                            hide-details
                            type="number"
                            label="Quantity"
                            prepend-icon="mdi-database"
                            :rules="[
                                rules.required,
                                rules.number,
                                maxValue(item),
                            ]"
                            required
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row no-gutters class="my-4" ref="notes">
                    <v-col cols="12">
                        <v-textarea
                            v-model="entry.notes"
                            rows="4"
                            hide-details
                            label="Notes"
                            prepend-icon="mdi-text"
                        >
                        </v-textarea>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                rounded
                color="primary"
                :loading="loading"
                :disabled="!valid"
                class="mb-5 mt-n2"
                @click="save"
            >
                {{ createForm ? `ADD NEW ENTRY` : `EDIT ENTRY` }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { objDiff } from '@/helpers/objDiff.js'
import { mapMutations } from 'vuex'
import API from '@/services/api'

export default {
    name: 'AddNewEntry',
    props: {
        request: {
            type: Object,
            required: true,
            default: () => ({}),
        },
        item: {
            type: Object,
            required: true,
            default: () => ({}),
        },
        entry: {
            type: Object,
            required: true,
            default: () => ({}),
        },
        originalEntry: {
            type: Object,
            required: true,
            default: () => ({}),
        },
        createForm: Boolean,
    },
    data: () => ({
        loading: false,
        menu: false,
        valid: false,
        rules: {
            required: v => !!v || 'The value is required',
            number: v => v > -1 || 'The value is invalid',
        },
    }),
    computed: {
        entryDiff() {
            if (!this.createForm) {
                return objDiff(this.originalEntry, this.entry)
            } else {
                return null
            }
        },
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        save() {
            if (this.createForm) {
                this.createEntry()
            } else {
                this.updateEntry()
            }
        },
        async createEntry() {
            try {
                this.loading = true
                const entry = await API.addEntryToRequestsItem({
                    requestId: this.request.id,
                    itemId: this.item.id,
                    ...this.entry,
                })
                entry.createdOn = entry.createdOn.toString().substring(0, 10)
                this.$emit('addEntry', entry)
                this.close()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async updateEntry() {
            try {
                this.loading = true
                const entry = await API.updateEntryToRequestsItem({
                    requestId: this.request.id,
                    itemId: this.item.id,
                    entryId: this.entry.id,
                    ...this.entryDiff,
                })
                this.$emit('replaceEntry', this.item.id, entry)
                this.close()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        close() {
            this.$emit('closeDialog')
        },
        maxValue(item) {
            item.totalQuantityEntries = 0
            if (item.entries) {
                item.entries.forEach(entry => {
                    if (entry.quantity) {
                        item.totalQuantityEntries += entry.quantity
                    }
                })
            }
            const maxValue = item.quantity - item.totalQuantityEntries

            if (Number(this.entry.quantity) > maxValue) {
                return `The value cannot be greater than ${maxValue}.`
            } else {
                return true
            }
        },
    },
}
</script>

<style></style>
