<template>
    <v-card :loading="loading">
        <v-card-title class="text-h5">Delete Entry</v-card-title>
        <v-card-text>
            Are you sure you want to delete this entry?
        </v-card-text>
        <v-card-actions>
            <v-btn text color="secondary" @click="close">
                Close
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn @click="deleteEntry" text color="error" :loading="loading">
                Delete
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'
export default {
    name: 'DeleteEntry',
    props: {
        selectedItem: {
            type: Object,
            required: true,
        },
        entry: {
            type: Object,
            required: true,
        },
        purchaseId: {
            type: String,
            required: true,
        },
    },
    data: () => ({
        loading: false,
    }),
    methods: {
        ...mapMutations(['setErrorItems']),
        close() {
            this.$emit('close')
        },
        async deleteEntry() {
            try {
                this.loading = true
                await API.deleteEntry({
                    purchaseId: this.purchaseId,
                    itemId: this.selectedItem.id,
                    id: this.entry.id,
                })
                this.$emit('remove')
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
}
</script>

<style></style>
